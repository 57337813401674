import React from 'react'
import csrfToken from '../../src/csrf_token'

class CartLoginPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      inputValue: '',
      error: false
    }

    this.updateInputValue = this.updateInputValue.bind(this)
    this.validate = this.validate.bind(this)
  }

  validate(event) {
    const { inputValue } = this.state

    if (!inputValue) {
      this.setState({
        error: true
      })

      event.preventDefault()
    }
  }

  updateInputValue(event) {
    this.setState({
      inputValue: event.target.value
    })
  }

  render() {
    const { inputValue, error } = this.state
    const { toStepOne, email } = this.props
    const url = `${window.location.origin}/auth/login`

    return (
      <form action={url} method="POST">
        <div className="cart-login-password">
          <div className="cart-login-password-text-container">
            <div className="cart-login-password-text">
              Enter your password to login
            </div>
          </div>

          <div className="cart-login-password-email-container">
            <div className="cart-login-password-email-field">
              <div className="cart-login-password-email-text">Email Address:*</div>
              <div className="cart-login-password-email-adress">{email}</div>
            </div>
            <div
              className="cart-login-password-email-edit-button"
              onClick={toStepOne}
            >
              <div className="cart-login-password-email-edit-button-text">
                Edit
              </div>
            </div>
          </div>

          <div className="cart-login-password-password-container">
            <div className="cart-login-password-password-label">
              <label htmlFor="password-input">Password:<span className="red-asterisk">*</span></label>
            </div>
            {error ? (
              <div className="error-container">
                <div className="error-text">
                  Type password
                </div>
              </div>
            ) : null}
            <div className="cart-login-password-password-input">
              <input
                type="password"
                id="password-input"
                name="user[password]"
                value={inputValue}
                onChange={this.updateInputValue}
                className={error ? 'red-border' : ''}
              />
            </div>
            <input
              type="hidden"
              name="user[email]"
              value={email}
            />
            <input
              type="hidden"
              name="authenticity_token"
              value={csrfToken()}
            />
            <input
              type="hidden"
              name="checkout"
              value={true}
            />
          </div>
          <div className="cart-login-password-forgotten-container">
            <div className="cart-login-password-forgotten-text">
              <a href="/forgot_password" title="Click here if you forgot your password">Forgotten your password?</a>
            </div>
          </div>

          <div className="cart-login-password-continue-button-container">
            <button
              className="cart-login-password-continue-button"
              onClick={this.validate}
            >
              <div className="cart-login-password-continue-button-text">
                Continue
              </div>
            </button>
          </div>
        </div>
      </form>
    )
  }
}

export default CartLoginPassword
